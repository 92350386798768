//LIBS
import { parseDomain, fromUrl, ParseResultType } from "parse-domain";

//TYPES
import { ServerProps } from "../models/Server";
import { SiteProps } from "../models/Site";

const validateServer = (server: string | undefined, maxChars: number) => {
  if (!server) return "Unknown";
  if (server.toLowerCase().includes("apache")) return "Apache";
  if (server.toLowerCase().includes("nginx")) return "Nginx";
  if (server.toLowerCase().includes("iis")) return "IIS";
  if (server.toLowerCase().includes("gws")) return "Google Web Services";
  if (server.toLowerCase().includes("cloudflare")) return "Cloudflare";
  if (server.length > maxChars) return server.substring(0, maxChars) + "...";
  return server;
};

export const getServerName = (site: SiteProps, servers: ServerProps[], maxChars: number = 25) => {
  if (servers && servers.length === 0) servers = [];

  {
    return servers.find((server) => server.ip === site.ip)?.name || validateServer(site.serverName, maxChars);
  }
};

export const getRegistrar = (whois: string): string => {
  if (whois === "") return "Unknown";
  let registrar = whois.match(/Registrar: (.*)/);
  if (whois.toLowerCase().includes("id: 886")) return "Dotster";
  if (registrar) {
    if (registrar[1].toLowerCase().includes("godaddy") || registrar[1].toLowerCase().includes("go daddy"))
      return "GoDaddy";
    if (registrar[1].toLowerCase().includes("namecheap")) return "Namecheap";
    if (registrar[1].toLowerCase().includes("webnames")) return "Webnames";
    if (registrar[1].toLowerCase().includes("tucows")) return "Tucows";
    if (registrar[1].toLowerCase().includes("namespro")) return "Namespro";
    if (registrar[1].toLowerCase().includes("name.com")) return "Name.com";
    if (registrar[1].toLowerCase().includes("network solutions")) return "Network Solutions";
    if (registrar[1].toLowerCase().includes("10dollar.ca")) return "10dollar.ca";
    if (registrar[1].toLowerCase().includes("wild west domains")) return "Wild West Domains";
    if (registrar[1].toLowerCase().includes("go get canada")) return "Go Get Canada";
    if (registrar[1].toLowerCase().includes("csc corporate")) return "CSC Corporate";

    return registrar[1];
  }
  return "Unknown";
};

export const getInitials = (name: string | null) => {
  if (!name) return "";
  const names = name.split(" ");
  if (names.length === 1) return names[0].substring(0, 2);
  return names[0][0] + names[names.length - 1][0];
};

export const getDomain = (url: string): string => {
  const domain = url.match(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)/);
  if (domain) return domain[1];
  return "";
};

export const getDomainWithoutSubdomain = (url: string): string => {
  const domain = parseDomain(fromUrl(url));
  if (domain.type === ParseResultType.Listed) {
    return `${domain.domain}.${domain.topLevelDomains.join(".")}`;
  } else {
    return url;
  }
};

export const addProxyToURL = (path: string) => {
  return "/api/proxy?path=" + path;
};

export const getServerStatusFromIndicator = (indicator: string) => {
  switch (indicator) {
    case "none":
      return "Systems OK";
    case "ok":
      return "Systems OK";
    case "minor":
      return "Minor Outage";
    case "major":
      return "Major Outage";
    case "critical":
      return "Critical Outage";
    case "active":
      return "Active Outage";
    default:
      return indicator;
  }
};

export const validateEmail = (email: string) => {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!emailRegex.test(email)) return { valid: false, msg: "Please enter a valid email" };
  if (email.indexOf("@ballisticarts.com") === -1) return { valid: false, msg: "Please use your Ballistic Arts email" };

  return { valid: true, msg: "" };
};

export const fieldsAreFilled = (fields: string[]) => {
  return fields.every((field) => field !== "");
};

export const isURLValid = (url: string) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  if (pattern.test(url)) return url;
  if (pattern.test("https://" + url)) return "https://" + url;
  return false;
};

export const addElipsis = (text: string, maxChars: number) => {
  if (text.length > maxChars) return text.substring(0, maxChars) + "...";
  return text;
};
