//NEXTJS
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";

//COMPONENTS
import Notification from "../../components/Notifications";
import UserInfo from "../../components/UserInfo";
import { CircularProgress, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import {
  Dashboard,
  Devices,
  CloudQueue,
  PrecisionManufacturing,
  EmojiPeople,
  Feedback,
  Storage,
  Preview,
  BugReport,
  Link as LinkIcon,
  MenuBook,
} from "@mui/icons-material";

//LIBS
import { UserContext } from "../../lib/hooks/userContext";
import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

//CONSTS
const MENU_ITEMS = [
  {
    label: "Dashboard",
    href: "/",
    icon: Dashboard,
    allow: ["basic", "dev", "admin", "bot"],
  },
  {
    label: "Sites",
    href: "/sites",
    icon: Devices,
    allow: ["basic", "dev", "admin"],
  },
  {
    label: "Bots",
    href: "/bots",
    icon: PrecisionManufacturing,
    allow: ["dev", "admin", "bot"],
  },
  {
    label: "Logs",
    href: "/logs",
    icon: Feedback,
    allow: ["dev", "admin"],
  },
  { label: "divider", icon: "</>", allow: ["basic", "dev", "admin"] },
  {
    label: "System Status",
    href: "/status",
    icon: CloudQueue,
    allow: ["basic", "dev", "admin"],
  },
  {
    label: "Site Lookup",
    href: "/lookup",
    icon: Preview,
    allow: ["basic", "dev", "admin"],
  },
  {
    label: "Bug Report",
    href: "/bugs",
    icon: BugReport,
    allow: ["basic", "dev", "admin"],
  },
  {
    label: "Vanity Links",
    href: "/links",
    icon: LinkIcon,
    allow: ["basic", "dev", "admin"],
  },
  {
    label: "Knowledge Base",
    href: "http://ballisticp2p.yourballistic.com/",
    icon: MenuBook,
    allow: ["basic", "dev", "admin"],
  },
  { label: "divider", icon: "</>", allow: ["admin"] },
  {
    label: "Servers",
    href: "/servers",
    icon: Storage,
    allow: ["admin"],
  },
  {
    label: "Users",
    href: "/users",
    icon: EmojiPeople,
    allow: ["admin"],
  },
];

const THEME = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#2D303E",
    },
    secondary: {
      main: "#ba51d9",
    },
    error: {
      main: "#E63970",
    },
    warning: {
      main: "#EDA128",
    },
    info: {
      main: "#4EA3C9",
    },
    success: {
      main: "#38AA49",
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "var(--light)",
          },
        },
      },
    },
  },
});

//CSS
import styles from "./styles.module.scss";

//TYPES
type DashboardLayoutProps = {
  children?: React.ReactNode | React.ReactNodeArray;
  centralize?: boolean;
  snack?: any;
};

const DashboardLayout = ({ children, centralize = false, snack = null }: DashboardLayoutProps) => {
  const user = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  //Setup loading state logic
  const router = useRouter();
  if (router.events) {
    router.events.on("routeChangeStart", () => {
      setLoading(true);
    });
    router.events.on("routeChangeComplete", () => {
      setLoading(false);
    });
    router.events.on("routeChangeError", () => {
      setLoading(false);
    });
  }

  const MenuItems = () => {
    if (!user) return null;
    return (
      <>
        {MENU_ITEMS.map((item, key: number) => {
          //Check if logged user has permission to access this route
          const intersectingRoles = user.roles.filter((value: string) => item.allow.includes(value));
          if (intersectingRoles.length > 0) {
            //Check if current item is a divider
            if (item.label === "divider" || !item.href || item.href === "") {
              return <div className={styles.divider} key={key} />;
            }

            //Create menu item
            let listItem = (
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <item.icon color="info" />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            );

            //Check if item links externally
            if (!item.href.startsWith("/")) {
              return (
                <a href={item.href} key={item.href} target="_blank" rel="noopener noreferrer">
                  {listItem}
                </a>
              );
            }

            //Return menu item
            return (
              <Link href={item.href} key={item.href}>
                {listItem}
              </Link>
            );
          }
          return null;
        })}
      </>
    );
  };

  return (
    <ThemeProvider theme={THEME}>
      <CssBaseline />
      <div className={`${styles.dashboardLayout} ${user ? styles.loggedIn : ""}`}>
        <header>
          <Link href="/">
            <a>
              <Image src="/images/logos/ballisticArts.png" alt="Dashboard" width={197} height={32} priority />
            </a>
          </Link>
          {user && (
            <div className={styles.headerIcons}>
              <Notification />
              <UserInfo />
            </div>
          )}
        </header>
        <div className={styles.container}>
          {user && (
            <aside>
              <nav>
                <MenuItems />
              </nav>
            </aside>
          )}
          <main className={centralize || loading ? styles.centralize : ""}>
            {loading ? <CircularProgress color="warning" size="3em" /> : children}
          </main>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default DashboardLayout;
