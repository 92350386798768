//NEXTJS
import { useState } from "react";

//COMPONENTS
import { Badge, IconButton, Menu, MenuItem } from "@mui/material";
import { Notifications } from "@mui/icons-material";

const Notification = ({ notifications = [] }: any) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => setOpen(false);

  //TODO: Finish this component

  if (notifications.length === 0) return null;

  return (
    <>
      <IconButton style={{ color: "var(--light)" }} onClick={handleClick}>
        <Badge color="error" variant="dot" badgeContent={notifications.length}>
          <Notifications fontSize="small"></Notifications>
        </Badge>
      </IconButton>
      <Menu open={open} onClose={handleClose} anchorEl={anchorEl} sx={{ mt: 1.5, pt: 0, pb: 0 }}>
        {notifications.map((notification: any, key: number) => (
          <MenuItem key={key}>
            <div>
              <span>{notification.title}</span>
              <span>{notification.message}</span>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Notification;
