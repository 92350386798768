//NEXTJS
import { useContext, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

//LIBS
import { UserContext } from "../../lib/hooks/userContext";
import { removeCookies } from "cookies-next";
import { getInitials } from "../../lib/functions/string";

//COMPONENTS
import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";

//CONSTS
import { COLORS } from "../../lib/consts";

const UserInfo = () => {
  const user = useContext(UserContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const router = useRouter();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const openPreferences = () => {
    router.push("/settings");
    setOpen(false);
  };

  const logout = () => {
    removeCookies("authToken");
    window.location.href = "/login";
  };

  //If user is logged in, show user info
  if (user) {
    return (
      <>
        <IconButton onClick={handleClick}>
          <Avatar
            sx={{ height: "30px", width: "30px", fontSize: "14px", bgcolor: COLORS[user.name.length % COLORS.length] }}
          >
            {getInitials(user.name)}
          </Avatar>
        </IconButton>
        <Menu open={open} onClose={() => setOpen(false)} anchorEl={anchorEl}>
          <MenuItem onClick={openPreferences} sx={{ fontSize: 13 }}>
            Settings
          </MenuItem>
          <MenuItem onClick={logout} sx={{ fontSize: 13 }}>
            Logout
          </MenuItem>
        </Menu>
      </>
    );
  }

  //If user is not logged in, show a login button
  return (
    <Link href="/login">
      <IconButton style={{ color: "var(--light)" }}>
        <AccountCircle fontSize="small"></AccountCircle>
      </IconButton>
    </Link>
  );
};

export default UserInfo;
