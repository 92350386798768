//NEXTJS
import { FormEvent, useState } from "react";

//COMPONENTS
import DashboardLayout from "../layouts/DashboardLayout";
import { Avatar, TextField, Link, Box, Typography, Container, Alert } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

//LIBS
import { setCookies } from "cookies-next";

//CSS
import styles from "../styles/pages/login.module.scss";
import { fieldsAreFilled, validateEmail } from "../lib/functions/string";

const simpleEncryption = (text: string) => {
  const BA_SALT = "B4ll1st1cS4lt";
  const textToChars = (text: string) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n: any) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code: any) => textToChars(BA_SALT).reduce((a, b) => a ^ b, code);

  return text.split("").map(textToChars).map(applySaltToChar).map(byteHex).join("");
};

const Login = () => {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const failSubmit = (msg: string) => {
    setErrorMsg(msg);
    setLoading(false);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const { email, password } = e.currentTarget as any;
    let user = {
      email: email.value.trim(),
      password: password.value.trim(),
    };

    if (!fieldsAreFilled([user.email, user.password])) return failSubmit("Please fill all fields");

    let emailValidation = validateEmail(user.email);
    if (!emailValidation.valid) {
      failSubmit(emailValidation.msg);
      return;
    }

    user.password = simpleEncryption(user.password);

    fetch("/api/users/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setCookies("authToken", data.token);
            window.location.href = "/";
          });
        } else {
          res.json().then((data: any) => {
            failSubmit(data.message);
          });
        }
      })
      .catch((err: any) => {
        failSubmit(err.message);
      });
  };

  return (
    <DashboardLayout centralize>
      <Container maxWidth="xs" className={styles.login}>
        <Box>
          <Avatar sx={{ m: 1, mb: 3, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography textAlign="center">Welcome to</Typography>
          <Typography component="h1" variant="h5" textAlign="center">
            Ballistic Arts Web Dashboard
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 4 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              size="small"
              variant="filled"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              size="small"
              variant="filled"
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
            >
              Sign In
            </LoadingButton>
            {errorMsg !== null && (
              <Alert severity="error" sx={{ mt: 2, mb: 3 }}>
                {errorMsg}
              </Alert>
            )}
            <Typography fontSize="0.55em" textAlign="center" sx={{ mt: 1 }}>
              <Link href="#" variant="caption" textAlign="center">
                Forgot password?
              </Link>
              <br />
              <br />
              If you don&apos;t yet have an account, request access from your manager.
            </Typography>
          </Box>
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default Login;
